<template>
    <svg :width="size" :height="size" viewBox="0, 0, 24, 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
        <template v-if="gradation.color">
            <defs>
                <linearGradient
                    :id="name"
                    :x1="gradation.degree ? gradation.degree.x1 : 0"
                    :x2="gradation.degree ? gradation.degree.x2 : 1"
                    :y1="gradation.degree ? gradation.degree.y1 : 1"
                    :y2="gradation.degree ? gradation.degree.y2 : 1"
                >
                    <stop
                        v-for="(data, index) in gradation.color"
                        :key="`gradation${index}`"
                        :offset="data.offset"
                        :stop-color="data.color"
                        :stop-opacity="data.opacity"
                    />
                </linearGradient>
            </defs>
            <path :fill="`url(#${name})`" :d="setSvgPath" />
        </template>
        <path v-else :fill="color" :d="setSvgPath" />
    </svg>
</template>

<script>
    "use strict";

    export default {
        "props": {
            "color": {
                "type": String,
                "required": false,
                "default": "#fff",
            },
            "gradation": {
                "type": Object,
                "required": false,
                "default": function () {
                    return {};
                },
            },
            "name": {
                "type": String,
                "required": true,
            },
            "path": {
                "type": String,
                "required": false,
            },
            "size": {
                "type": String,
                "required": false,
                "default": "24",
            },
        },
        "computed": {
            setSvgPath() {
                const name = this.name;
                let d = "";
                if (this.path) {
                    d = this.path;
                }
                else if (name === "user") {
                    d = "M4.781 24h-.001c-.603 0-1.097-.475-1.123-1.079-.129-3.12-.492-11.95-.492-11.95l-1.716 3.106c-.186.339-.593.486-.953.347l-.001-.001c-.304-.118-.495-.407-.495-.72l.029-.21 1.659-5.856c.163-.574.687-.97 1.284-.97h18.056c.597 0 1.121.396 1.284.97l1.659 5.856.029.21c0 .313-.191.602-.495.72l-.001.001c-.36.139-.767-.008-.953-.347l-1.716-3.106s-.363 8.83-.492 11.95c-.026.604-.52 1.079-1.123 1.079h-.001c-.591 0-1.075-.459-1.12-1.047-.102-1.297-.374-4.4-.456-5.628-.034-.51-.407-.81-.812-.81-.363 0-.771.3-.804.81-.083 1.228-.355 4.331-.456 5.628-.046.588-.53 1.047-1.12 1.047h-.002c-.602 0-1.097-.475-1.122-1.079-.13-3.12-.644-12.921-.644-12.921h-1.348s-.532 9.801-.662 12.921c-.025.604-.52 1.079-1.122 1.079h-.002c-.59 0-1.074-.459-1.12-1.047-.101-1.297-.373-4.4-.456-5.628-.033-.51-.441-.81-.804-.81-.405 0-.778.3-.812.81-.082 1.228-.354 4.331-.456 5.628-.045.588-.529 1.047-1.12 1.047zm2.384-24c1.656 0 3 1.344 3 3s-1.344 3-3 3c-1.655 0-3-1.344-3-3s1.345-3 3-3zm9.67 0c-1.656 0-3 1.344-3 3s1.344 3 3 3c1.655 0 3-1.344 3-3s-1.345-3-3-3z";
                }
                else if (name === "item") {
                    d = "M23 6.066v12.065l-11.001 5.869-11-5.869v-12.131l11-6 11.001 6.066zm-21.001 11.465l9.5 5.069v-10.57l-9.5-4.946v10.447zm20.001-10.388l-9.501 4.889v10.568l9.501-5.069v-10.388zm-5.52 1.716l-9.534-4.964-4.349 2.373 9.404 4.896 4.479-2.305zm-8.476-5.541l9.565 4.98 3.832-1.972-9.405-5.185-3.992 2.177z";
                }
                else if (name === "order") {
                    d = "M21.155 8.64c-.909 1.519-2.327 3.067-4.097 3.004-.413.706-.852 1.677-1.339 2.803l-1.312.553c.936-2.343 2.231-4.961 3.698-6.994-.67.529-1.746 1.637-2.662 2.783-1.098-1.828-.3-3.691.973-5.179.021.641.359 1.196.601 1.475-.087-.53-.114-1.489.195-2.351.718-.732 1.364-1.271 2.113-1.76-.083.478.08 1.026.262 1.361.024-.49.224-1.43.521-1.84.924-.727 2.332-1.373 3.892-1.495-.081.973-.436 2.575-1.024 3.604-.515.404-1.221.68-1.791.833.493.089 1.031.077 1.494-.001-.269.743-.552 1.428-.998 2.276-.679.468-1.578.732-2.203.825.46.187 1.272.245 1.677.103zm-13.841 3.805l.645.781 4.773-2.791-.668-.768-4.75 2.778zm6.96-.238l-.668-.767-4.805 2.808.645.781 4.828-2.822zm4.679.007c-.421.203-.851.341-1.286.398-.12.231-.246.494-.377.773l.298.342c.623.692.459 1.704-.376 2.239-.773.497-5.341 3.376-6.386 4.035-.074-.721-.358-1.391-.826-1.948-.469-.557-6.115-7.376-7.523-9.178-.469-.6-.575-1.245-.295-1.816.268-.549.842-.918 1.43-.918.919 0 1.408.655 1.549 1.215.16.641-.035 1.231-.623 1.685l1.329 1.624 7.796-4.446c1.422-1.051 1.822-2.991.93-4.513-.618-1.053-1.759-1.706-2.978-1.706-1.188 0-.793-.016-9.565 4.475-1.234.591-2.05 1.787-2.05 3.202 0 .87.308 1.756.889 2.487 1.427 1.794 7.561 9.185 7.616 9.257.371.493.427 1.119.15 1.673-.277.555-.812.886-1.429.886-.919 0-1.408-.655-1.549-1.216-.156-.629.012-1.208.604-1.654l-1.277-1.545c-.822.665-1.277 1.496-1.377 2.442-.232 2.205 1.525 3.993 3.613 3.993.596 0 1.311-.177 1.841-.51l9.427-5.946c.957-.664 1.492-1.781 1.492-2.897 0-.745-.24-1.454-.688-2.003l-.359-.43zm-7.933-10.062c.188-.087.398-.134.609-.134.532 0 .997.281 1.243.752.312.596.226 1.469-.548 1.912l-5.097 2.888c-.051-1.089-.579-2.081-1.455-2.732l5.248-2.686zm2.097 13.383l.361-.905.249-.609-3.449 2.017.645.781 2.194-1.284z";
                }
                else if (name === "driver") {
                    d = "M2.075 9.479c.266-.562.595-1.133.925-1.634v-1.845c0-3.878 3.822-6 9-6 5.316 0 9 2.043 9 6v1.845c.33.501.659 1.072.925 1.634 1.474.515 2.075 2.217 2.075 4.072 0 1.925-.896 4.738-3.317 5.188-1.959 3.492-4.877 5.261-8.683 5.261-3.805 0-6.724-1.769-8.683-5.261-2.42-.45-3.317-3.263-3.317-5.188 0-1.847.599-3.556 2.075-4.072zm19.865 3.19c-.42.306-1.038.39-1.724.204-1.538-.45-3.52-1.511-8.216-1.498-4.695-.013-6.678 1.048-8.216 1.498-.687.186-1.305.102-1.725-.205-.24 1.606.339 4.103 1.908 4.133.368.007.703.216.871.543 2.39 4.656 5.982 4.656 7.162 4.656 1.181 0 4.771 0 7.162-4.656.168-.327.503-.536.87-.543 1.853-.036 1.968-3.218 1.968-3.25 0-.324-.02-.62-.06-.882zm-6.94 4.331c-.002.007-.768 2.354-3 2.354-2.15 0-2.998-2.347-3-2.354h6zm-6.25-5c-.69 0-1.25.671-1.25 1.5 0 .828.56 1.5 1.25 1.5s1.25-.672 1.25-1.5c0-.829-.56-1.5-1.25-1.5zm7.75 1.5c0 .828-.56 1.5-1.25 1.5s-1.25-.672-1.25-1.5c0-.829.56-1.5 1.25-1.5s1.25.671 1.25 1.5zm3.01-4.254c-2.454-1.152-4.551-1.632-7.51-1.632-2.534 0-4.926.504-7.536 1.673-.337.546-.625 1.107-.79 1.522 2.904-1.005 4.789-1.439 8.326-1.426 3.64-.013 5.529.457 8.326 1.426-.17-.425-.468-1.005-.816-1.563zm-10.51-3.246h6v-3.17l-3 2-3-2v3.17zm5.996-4h-5.992l2.996 2 2.996-2z";
                }
                else if (name === "moduleshop") {
                    d = "M16 6v-2c0-2.209-1.791-4-4-4s-4 1.791-4 4v2h-5v18h18v-18h-5zm-7-2c0-1.654 1.346-3 3-3s3 1.346 3 3v2h-6v-2zm10 18h-14v-14h3v1.5c0 .276.224.5.5.5s.5-.224.5-.5v-1.5h6v1.5c0 .276.224.5.5.5s.5-.224.5-.5v-1.5h3v14z";
                }
                else if (name === "receptionTime") {
                    d = "M11 6v8h7v-2h-5v-6h-2zm10.854 7.683l1.998.159c-.132.854-.351 1.676-.652 2.46l-1.8-.905c.2-.551.353-1.123.454-1.714zm-2.548 7.826l-1.413-1.443c-.486.356-1.006.668-1.555.933l.669 1.899c.821-.377 1.591-.844 2.299-1.389zm1.226-4.309c-.335.546-.719 1.057-1.149 1.528l1.404 1.433c.583-.627 1.099-1.316 1.539-2.058l-1.794-.903zm-20.532-5.2c0 6.627 5.375 12 12.004 12 1.081 0 2.124-.156 3.12-.424l-.665-1.894c-.787.2-1.607.318-2.455.318-5.516 0-10.003-4.486-10.003-10s4.487-10 10.003-10c2.235 0 4.293.744 5.959 1.989l-2.05 2.049 7.015 1.354-1.355-7.013-2.184 2.183c-2.036-1.598-4.595-2.562-7.385-2.562-6.629 0-12.004 5.373-12.004 12zm23.773-2.359h-2.076c.163.661.261 1.344.288 2.047l2.015.161c-.01-.755-.085-1.494-.227-2.208z";
                }
                else if (name === "cloak") {
                    d = "M10.5 24h-7c-1.4 0-2.5-1.14-2.5-2.5v-11.64c-.662-.473-1-1.201-1-1.941 0-.376.089-.75.289-1.129 1.065-1.898 2.153-3.783 3.265-5.654.462-.737 1.211-1.136 2.045-1.136.635 0 .972.204 1.445.479.662.386 9 5.352 12.512 7.441.087.052 3.366 1.988 3.449 2.045.663.49.995 1.197.995 1.934 0 .375-.092.745-.295 1.13-1.064 1.899-2.153 3.784-3.265 5.655-.577.92-1.615 1.29-2.496 1.088-.392.234-5.826 3.75-6.252 3.968-.413.212-.762.26-1.192.26m-7.5-10.763v8.263c0 .274.221.5.5.5h4.588c-1.72-2.906-3.417-5.827-5.088-8.763m1.606-1.238c.053.092 5.681 9.797 5.726 9.859.108.139.299.181.455.098.164-.092 5.081-3.251 5.081-3.251-.639-.377-8.144-4.851-11.262-6.706m.659-9.829c-.913 1.456-3.199 5.525-3.235 5.61-.07.171-.008.366.149.464.201.12 16.023 9.547 16.177 9.571.151.022.297-.045.377-.174.942-1.584 3.206-5.55 3.232-5.601.069-.172.007-.367-.15-.465-.201-.12-15.983-9.499-16.09-9.546-.18-.074-.365-.002-.46.141m1.557 2.695c1.104 0 2 .897 2 2 0 1.104-.896 2-2 2s-2-.896-2-2c0-1.103.896-2 2-2";
                }
                else if (name === "closedDate") {
                    d = "M24 2v22h-24v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h10v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 6h-20v14h20v-14zm-2-7c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-14 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2zm1 11.729l.855-.791c1 .484 1.635.852 2.76 1.654 2.113-2.399 3.511-3.616 6.106-5.231l.279.64c-2.141 1.869-3.709 3.949-5.967 7.999-1.393-1.64-2.322-2.686-4.033-4.271z";
                }
                return d;
            },
        },
    };
</script>
