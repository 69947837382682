<template>
    <div v-if="module.use || name !== 'menu'" :class="[$style.logo, classLayout]">
        <div :class="$style.logo__block">
            <Icon
                :color="module.option.mark ? module.option.mark.color : '#fff'"
                :gradation="module.option.mark ? module.option.mark.gradation : {}"
                :name="name"
                :path="module.option.mark ? module.option.mark.path : ''"
                :size="markSize"
            />
        </div>
        <div :class="$style.logo__block">
            <p
                v-if="module.option.character"
                v-html="module.option.character.main.text"
                :class="[$style.logo__block__text, $style.mainText]"
                :style="module.option.character.main.gradation ? {
                    'background': styleGradation('main'),
                    '-webkit-background-clip': 'text',
                    '-webkit-text-fill-color': 'transparent',
                } : {
                    'color': module.option.character.main.color,
                }"
            >
            </p>
            <p
                v-if="module.option.character"
                :class="[$style.logo__block__text, $style.subText]"
                :style="module.option.character.sub.gradation ? {
                    'background': styleGradation('sub'),
                    '-webkit-background-clip': 'text',
                    '-webkit-text-fill-color': 'transparent',
                } : {
                    'color': module.option.character.sub.color,
                }"
            >
                {{ module.option.character.sub.text }}
            </p>
        </div>
    </div>
</template>

<script>
    "use strict";

    import Icon from "@/components/Icon.vue";

    import string from "@/mixins/string.js";

    export default {
        "components": {
            Icon,
        },
        "mixins": [
            string,
        ],
        "props": {
            "name": {
                "type": String,
                "required": false,
            },
        },
        data() {
            return {
                "module": this.$store.getters.getModules.logo,
            };
        },
        "computed": {
            // layouts別のclassを追加
            classLayout() {
                if (this.name) {
                    const adjName = this.$_initialUpperCase(this.name);
                    return this.$style[`is${adjName}`];
                }
            },
            markSize() {
                if (this.module.option.mark) {
                    let size = this.module.option.mark.size[this.name];
                    if (!this.name) {
                        size = "24";
                    }
                    return size;
                }
                else {
                    return "0";
                }
            },
        },
        created() {
            this.setTitle();
            this.$store.watch(
                (state, getters) => getters.getModules,
                () => {
                    this.module = this.$store.getters.getModules.logo;
                }
            );
        },
        "methods": {
            setTitle() {
                if (this.module.option.character) {
                    const character = this.module.option.character;
                    document.title = `${character.main.text.replace(/<br>/g, "")} - ${character.sub.text.replace(/<br>/g, "")}`;
                }
            },
            styleGradation(character) {
                const linear = "linear-gradient";
                const degree = this.module.option.character[character].gradation.degree > -1 ? this.module.option.character[character].gradation.degree : 90;
                let color = "";
                for (const data of this.module.option.character[character].gradation.color) {
                    if (!color) {
                        color = `${data.color} ${data.offset}`;
                    }
                    else {
                        color = `${color}, ${data.color} ${data.offset}`;
                    }
                }
                return `${linear}(${degree}deg, ${color})`;
            },
        },
    };
</script>

<style lang="scss" module>
    .logo {
        padding: 24px;
        text-align: center;
        @include sp {
            align-items: center;
            display: flex;
            height: 56px;
            justify-content: center;
            padding: 0;
        }
        > div {
            &:first-child {
                @include sp {
                    margin-right: 8px;
                }
            }
        }
        &__block {
            &__text {
                font-family: "Josefin Sans", $mincho;
                line-height: 1.2;
                text-align: center;
                @include sp {
                    line-height: 1;
                    text-align: left;
                }
                &.mainText {
                    font-size: 32px;
                    font-weight: bold;
                    padding-top: 8px;
                    @include sp {
                        font-size: 16px;
                        padding-top: 0;
                    }
                    > br {
                        @include sp {
                            display: none;
                        }
                    }
                }
                &.subText {
                    font-size: 13px;
                    @include sp {
                        font-size: 11px;
                    }
                }
            }
        }
    }
</style>
