var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.module.use || _vm.name !== 'menu')?_c('div',{class:[_vm.$style.logo, _vm.classLayout]},[_c('div',{class:_vm.$style.logo__block},[_c('Icon',{attrs:{"color":_vm.module.option.mark ? _vm.module.option.mark.color : '#fff',"gradation":_vm.module.option.mark ? _vm.module.option.mark.gradation : {},"name":_vm.name,"path":_vm.module.option.mark ? _vm.module.option.mark.path : '',"size":_vm.markSize}})],1),_c('div',{class:_vm.$style.logo__block},[(_vm.module.option.character)?_c('p',{class:[_vm.$style.logo__block__text, _vm.$style.mainText],style:(_vm.module.option.character.main.gradation ? {
                'background': _vm.styleGradation('main'),
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent',
            } : {
                'color': _vm.module.option.character.main.color,
            }),domProps:{"innerHTML":_vm._s(_vm.module.option.character.main.text)}}):_vm._e(),(_vm.module.option.character)?_c('p',{class:[_vm.$style.logo__block__text, _vm.$style.subText],style:(_vm.module.option.character.sub.gradation ? {
                'background': _vm.styleGradation('sub'),
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent',
            } : {
                'color': _vm.module.option.character.sub.color,
            })},[_vm._v(" "+_vm._s(_vm.module.option.character.sub.text)+" ")]):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }